import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { graphql } from "gatsby"
import * as actions from "../state/actions/keymans"
import { PREFECTURES } from "../constants/constants"
import BreadcrumbList from "../components/common/breadcrumb-list"
import { Link } from "gatsby"
import Layout from "../components/common/layout"
import SEO from "../components/common/seo"
import Banner from "../components/common/banner"
import Card from "../components/common/card"
import { Location } from "@reach/router"
import ServiceDescription from "../components/atoms/service-description"
import LinkToLpText from "../components/atoms/link-to-lp-text"
import LinkToUpdate from "../components/common/link-to-update"

export const query = graphql`
query CorporationsLocationQuery {
  allCorporationsLocationJson {
    edges {
      node {
        value
        prefecture_name
        city_name
        place_name
      }
    }
  }
}
`

const mapStateToProps = (state, ownProps) => {
  return {
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
  }
}

const styles = theme => ({
  subheading: {
    fontWeight: "bold",
    color: "#5A5B62",
    marginBottom: "18px",
  },

  scroll: {
    height: '450px',
    overflow: 'auto',
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '5px',
  },

  prefectureContent: {
    width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 10px #254e781a',
    boxSizing: 'border-box',
    marginBottom: '25px',
    '&:last-child': {
      marginBottom: 0,
    }
  },

  title: {
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '16px',
    padding: '22px 24px',
    borderBottom: '1px solid rgb(216, 219, 223)',
    display: 'flex',
    justifyContent: 'space-between',
  },

  row: {
    width: "100%",
    flexWrap: 'wrap',
    padding: '20px 24px',
    boxSizing: 'border-box',
  },

  prefectureLink: {
    fontSize: "14px",
    color: "#181819",
    width: "25%",
    display: "flex",
    alignItems: "flex-end",
    padding: '5px 0',
    '@media only screen and (min-width: 961px) and (max-width: 1200px)': {
      width: 'calc(100% / 3)',
    },

    '@media only screen and (min-width: 601px) and (max-width: 750px)': {
      width: 'calc(100% / 3)',
    },

    '@media only screen and (min-width: 390px) and (max-width: 600px)': {
      width: '50%',
    },

    '@media only screen and (max-width: 389px)': {
      width: '100%',
    },
  },

  arrowDown: {
    width: '6px',
    height: '6px',
    border: '2px solid',
    borderColor:  'transparent transparent #1866FF #1866FF',
    transform: 'rotate(-45deg)',
    margin: 'auto 0',
  },

  arrowTop: {
    width: '6px',
    height: '6px',
    border: '2px solid',
    borderColor: '#1866FF #1866FF transparent transparent',
    transform: 'rotate(-45deg)',
    margin: 'auto 0',
  },

  dliArrowRight: {
    display: 'inline-block',
    color: '#1866FF',
    position: 'relative',
    width: '11px',
    height: '2px',
    background: 'currentColor',
    margin: 'auto 0',

    '&::before': {
      content: '""',
      width: '7px',
      height: '7px',
      border: '2px solid currentColor',
      borderLeft: '0',
      borderBottom: '0',
      transform: 'rotate(45deg)',
      transformOrigin: 'top right',
      position: 'absolute',
      top: '50%',
      right: '-2px',
      boxSizing: 'border-box',
    }
  },
})

class CorporationsPrefecture extends React.Component {
  componentDidMount() {
    const { pageContext } = this.props
    const prefectures = PREFECTURES.find(prefecture => prefecture.value === pageContext.slug)
    if (prefectures) {
      prefectures.submenu.forEach(item => {
        this.setState({
          [item.name]: false
        })
      })
    }
  }

  togglePrefectureMenu = (prefecture) => {
    this.setState({ [prefecture]: !this.state[prefecture] })
  }

  // 都道府県の市町村を表示
  renderRow = (item) => {
    const { classes } = this.props
    return (
      <Link className={classes.prefectureLink} to={`/corporations-location-search/${item.node.place_name}/${item.node.value}`}>
        <div style={{ marginRight: "10px" }}>{item.node.city_name}</div>
        <div className={classes.dliArrowRight}></div>
      </Link>
    )
  }

  // 都道府県ごとに表示
  renderPrefectures = () => {
    const { classes, pageContext, data } = this.props
    // 地方の都道府県を取得
    let prefecturesInRegion = PREFECTURES.find(prefecture => prefecture.value === pageContext.slug)
    prefecturesInRegion = prefecturesInRegion ? prefecturesInRegion.submenu : []
    // 地方の都道府県の企業所在地の地域のjsonデータを取得
    const CorporationsLocationPrefectures = data.allCorporationsLocationJson.edges.filter(data => {
      return data.node.place_name === pageContext.slug
    })

    return (
      <div className={classes.scroll}>
      {prefecturesInRegion.map(prefecture => {
        return (
          <div className={classes.prefectureContent}>
            <div
              role='button'
              tabIndex='0'
              className={classes.title}
              onClick={() => this.togglePrefectureMenu(prefecture.name)}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  this.togglePrefectureMenu(prefecture.name)
                }
              }}
            >
              <div>{prefecture.value}</div>
              <div className={this.state && this.state[prefecture.name] ? classes.arrowTop : classes.arrowDown}></div>
            </div>
            <div className={classes.row} style={{ display: this.state && this.state[prefecture.name] ? 'flex' : 'none'}}>
            {CorporationsLocationPrefectures.map(item => {
              if (prefecture.value === item.node.prefecture_name) {
                return (
                  this.renderRow(item)
                )
              }
              return null
            })}
            </div>
          </div>
        )
      })}
      </div>
    )
  }

  render() {
    const {
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      path,
      pageContext,
    } = this.props
    return (
      <Layout hasFooterPopup={true}>
        {path && path.indexOf('/corporations-location-search') !== -1 && (
          <SEO
            title={`${pageContext.slug}地方の企業を探す`}
            description={`${pageContext.slug}地方の企業を探すことができます。大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`}
            canonical={`https://keyman-db.smart-letter.com${path}`}
          />
        )}
        <div className="container">
          <div className="list-container list-margin">
            <main className="left">
              <BreadcrumbList
                list={[
                  { path: "/", text: "トップ" },
                  { path: `/corporations-location-search`, text: '地域から企業を探す' },
                ]}
                current={{
                  path: path,
                  text: `${pageContext.slug}地方`,
                }}
              />
              <div>
                <h2 className="title">
                {pageContext.slug}地方の企業を探す
                </h2>
                <ServiceDescription
                  children={<LinkToLpText/>}
                />
              </div>
              {this.renderPrefectures()}
            </main>

            <div className="right">
              <div className="banner">
                <Banner short={true} />
              </div>

              <h2 className="title">昨日更新された決裁者の人数</h2>
              <LinkToUpdate
                keymanCountYstd={keymanCountYstd}
                updateKeymans={this.props.updateKeymans}
                isLoadingUpdateKeymans={this.props.isLoadingUpdateKeymans}
              />
              <Card
                keymanCountYstd={keymanCountYstd}
                keymansTotal={keymansTotal}
                corporationsTotal={corporationsTotal}
                style={{
                  marginBottom: "35px",
                }}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <CorporationsPrefecture {...locationProps} {...props} />}
    </Location>
  ))
)
